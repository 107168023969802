import { Injectable } from '@angular/core';
import { CanLoad, NavigationEnd, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  private lastVisitedUrl: string = '/';
  constructor (
    private authService: AuthService,
    private router: Router)
     {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.lastVisitedUrl = event.url;
          //console.log(event)
          //console.log(this.lastVisitedUrl)

        }
      });
     }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //return true;
    return this.authService.userIsAuthenticated.pipe(
      take(1),
      switchMap(isAuthenticated => {
        if (!isAuthenticated) {
          return this.authService.autoLogin();
          
        } else {
          return of (isAuthenticated);
        }
      }),
      tap(isAuthenticated => {
        if (!isAuthenticated) {
          this.router.navigateByUrl('/auth?redirect=%2Ftabs%2Fprojects')
        }
      })
    )
    }
    //this.authService.userIsAuthenticated.subscribe(dat => {
    //  console.log(dat)
    //})
    //return this.authService.userIsAuthenticated;
    //}
    

}
