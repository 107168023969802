import { Component, Input, OnInit, Output } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/auth/auth.service';
import { ProjectsService } from 'src/app/projects/projects.service';

export interface CreateProjectResponseData {
  message: string;
  records_added: number;
  data: any[];
}


@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss'],
})
export class AddProjectComponent implements OnInit {
  @Input() modalController: ModalController;
  
  projectName: string = '';
  projectDescription: string = '';
  email;

  constructor(
    public projectService: ProjectsService,
    private authService: AuthService,
    private alertController: AlertController
  ) {
    
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  async createProject() {
    const newProject = {
      id: 1,
      name: this.projectName,
      description: this.projectDescription,
    };
    console.log(newProject)
    if (!this.projectName || this.projectName.length === 0) {
      const alert = await this.alertController.create({
        header: 'Blank Name',
        message: 'Project name cannot be blank!',
        buttons: [
          {
            text: 'OK',
            role:'ok',
            handler: () => {
              //user clicked "ok", do nothing
            }

          }
        ]
      })
      await alert.present();
      return
    }

    // Call your API here to create the project using newProject data
    // Example: You can use Angular's HttpClient to make the API call
    // ...
    this.projectService.createProject<CreateProjectResponseData>(this.email,this.projectName,this.projectDescription)
    .subscribe((res: CreateProjectResponseData) => {
        console.log(res)
        if (res.message == 'project added!'){
          console.log('herex')
          this.projectService.setProjectData(res);
        }
        
      })
      
      console.log(newProject)
      console.log(this.projectName.length)
      
      this.dismissModal();
  }
  ngOnInit() {
    this.authService.userId.subscribe(email => {
      this.email = email;
    })
  }


}
