import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  //private apiUrl = 'https://your-backend-api-url.com/contact'; // Replace with your backend URL

  private apiUrl: string;


  constructor(private http: HttpClient) {
    this.apiUrl = `${window.location.protocol}//${window.location.hostname}:3000/api`;
   }

  sendMessage(messageData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/contact`, messageData);
  }
}
