import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/auth/auth.service';
import { ProjectsService } from 'src/app/projects/projects.service';
import { CreateProjectResponseData } from '../add-project/add-project.component';
import { Router } from '@angular/router';

interface Project {
  id: number;
  name: string;
  description: string;
  created_dt: Date;
  email: string;

}


@Component({
  selector: 'app-add-product-to-project',
  templateUrl: './add-product-to-project.component.html',
  styleUrls: ['./add-product-to-project.component.scss'],
})
export class AddProductToProjectComponent implements OnInit {
  @Input() modalController: ModalController;
  @Input() id;

  projects: Project[] = [];
  isLoading = false;
  email;

  selectedProjectIds: number[] = [];


  constructor(
    public projectService: ProjectsService,
    private authService: AuthService,
    private router: Router,
    private alertController: AlertController
  ) { }

  close() {
      this.modalController.dismiss();
    }
  

  ngOnInit() {
    this.isLoading = true;
    this.authService.userId.subscribe(email => {
      this.email = email;
      console.log(this.email);
    })

    this.projectService.getProjects(this.email)
      .subscribe(res => {
        console.log(res);
        this.projects = res.data;
        

      })
    this.isLoading = false;

    this.projectService.projectData$.subscribe(data => {
      if (data){
        const newProjectResponse: CreateProjectResponseData = data.data[0];
        console.log('New Project Response:', newProjectResponse)
        this.projects.push(data.data[0])
      }
      

    })
    console.log(this.email);

}

loginDirect(){
  this.close();
  this.router.navigateByUrl("/auth")
}

toggleProjectSelection(project: any) {
  if (project.checked) {
    // Add the project.id to the selectedProjectIds array
    this.selectedProjectIds.push(project.id);
  } else {
    // Remove the project.id from the selectedProjectIds array
    const index = this.selectedProjectIds.indexOf(project.id);
    if (index !== -1) {
      this.selectedProjectIds.splice(index, 1);
    }
  }
  console.log(this.selectedProjectIds)
}

async presentAlert(length: number) {
  if (length>0){
    const alert = await this.alertController.create({
      header: 'Added to Projects',
      message: 'This item has been added to the selected projects.',
      buttons: [{
        text: 'OK',
        role:'ok',
        handler: () => {
          //user clicked "ok", do nothing
          this.close();
        }
  
      }]
    })
    await alert.present();

  } else {
    const alert = await this.alertController.create({
      header: 'Select 1 project',
      message: 'Please select at least 1 project',
      buttons: [{
        text: 'OK',
        role:'ok',
        handler: () => {
          //user clicked "ok", do nothing
          
        }
  
      }]
    })
    await alert.present();
  }
  
  
  ;

  
}

confirm() {
  console.log(this.selectedProjectIds)
  if (this.selectedProjectIds.length == 0) {
    this.presentAlert(this.selectedProjectIds.length)


  } else {
    this.projectService.addProductToProject(this.id, this.selectedProjectIds,this.email)
    this.presentAlert(this.selectedProjectIds.length)

  }
  
}

}
