import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { IonicModule } from '@ionic/angular';
import { FooterComponent } from './footer/footer.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { CompareItemsComponent } from './compare-items/compare-items.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { ProductFilterComponent } from './product-filter/product-filter.component';
import { FormsModule } from '@angular/forms';
import { AddProjectComponent } from './add-project/add-project.component';
import { AddProductToProjectComponent } from './add-product-to-project/add-product-to-project.component';
import { RouterModule } from '@angular/router';
import { HideHeaderDirective } from './directives/hide-header.directive';





@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ProductDetailComponent,
    CompareItemsComponent,
    ProductCardComponent,
    ProductFilterComponent,
    AddProjectComponent,
    AddProductToProjectComponent,
    HideHeaderDirective
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    RouterModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ProductDetailComponent,
    CompareItemsComponent,
    ProductCardComponent,
    ProductFilterComponent,
    AddProjectComponent,
    AddProductToProjectComponent,
    HideHeaderDirective
  ]
})
export class SharedModule { }
