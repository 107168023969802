import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProductsService } from 'src/app/product/products.service';

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.scss'],
})
export class ProductFilterComponent implements OnInit {
  isLoading = false;
  filterCriteria;
  productSum;

  brands = new Set();
  brandNames;
  
  clearanceValues = new Set();
  clearance;
  
  deliveryValues = new Set();
  delivery;
  
  clickcollectValues = new Set();
  clickcollect;
  


  highestPrice = -Infinity;
  lowestPrice = Infinity;
  highestSavingAmount = -Infinity;
  lowestSavingAmount = Infinity;
  highestSavingPc = -Infinity;
  lowestSavingPc = Infinity;






  constructor(
    private modalCtrl: ModalController,
    private productService: ProductsService
    ) { }

  ngOnInit() {
    this.isLoading = true;
    console.log(this.isLoading);
  this.productService.getFilterCriteria()
    .subscribe(filterCriteria => {
      this.filterCriteria = filterCriteria;
      console.log(filterCriteria)
      this.isLoading = false;
      console.log(this.isLoading);
      
      
      this.filterCriteria.rows.forEach(element => {
        this.brands.add(element.brand);
        this.clearanceValues.add(element.clearance);
        this.deliveryValues.add(element.delivery);
        this.clickcollectValues.add(element.clickcollect);


        //console.log(+element.lowestprice);
        if ((+element.lowestprice) < this.lowestPrice) {
          this.lowestPrice = (+element.lowestprice);
        }
        if ((+element.highestprice) > this.highestPrice) {
          this.highestPrice = (+element.highestprice);
        }

        if ((+element.lowestsavingamount) < this.lowestSavingAmount) {
          this.lowestSavingAmount = (+element.lowestsavingamount);
        }
        if ((+element.highestsavingamount) > this.highestSavingAmount) {
          this.highestSavingAmount = (+element.highestsavingamount);
        }

        if ((+element.lowestsavingpc) < this.lowestSavingPc) {
          this.lowestSavingPc = (+element.lowestsavingpc);
        }
        if ((+element.highestsavingpc) > this.highestSavingPc) {
          this.highestSavingPc = (+element.highestsavingpc);
        }

      });
      this.brandNames = Array.from(this.brands);
      this.clearance = Array.from(this.clearanceValues);
      this.delivery = Array.from(this.deliveryValues);
      this.clickcollect = Array.from(this.clickcollectValues);

      console.log(this.brandNames);
      console.log(this.lowestPrice);
      console.log(this.highestPrice);
      console.log(this.lowestSavingAmount);
      console.log(this.highestSavingAmount);
      console.log(this.lowestSavingPc);
      console.log(this.highestSavingPc);

      console.log(this.clearance);
      console.log(this.delivery);
      console.log(this.clickcollect);


    })

  }
  
  prodSum() {
    let total = this.filterCriteria.rows.reduce((acc,obj) => acc + (+obj.productcount), 0)
    return total
  }







  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }
  



}
