import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { AuthService } from "./auth.service";

//import { User } from './user.model';

@Injectable(
    {providedIn: 'root'}
)

export class AuthInterceptor implements HttpInterceptor {
    //private _user = User;
    constructor(
        private authService: AuthService
        ) {}

    
    authToken: string;
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        //console.log('3433')
        //const authToken = this.user.token;

        
        this.authService.token.pipe(
            take(1),
            
            switchMap( (token: string) => {
            //console.log(token)
            this.authToken  = token;
            return of(null);
            
        })).subscribe();
        
        /*
        this.authService.token.pipe(switchMap(token => {
            //this.authToken =  ;
            //console.log(token)
        }));
        */
       
       //console.log(authToken);

       const authRequest = req.clone({
           headers: req.headers.set('Authorization', "Bearer " + this.authToken)
       });
       //console.log(authRequest);
       return next.handle(authRequest);
       
    }
}