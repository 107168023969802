import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Preferences } from '@capacitor/preferences';
import { User } from './user.model';
//import { Plugins } from '@capacitor/core';
//const { Storage } = Plugins;



export interface AuthResponseData {
  message: string;
  email: string;
  token?: string;
  expiresAt?: string;
  expiresIn?: string;
  
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _user = new BehaviorSubject<User>(null);
  //private _token = new BehaviorSubject<string>();
  private apiUrl: string;

  constructor(private http: HttpClient) { 
    this.apiUrl = `${window.location.protocol}//${window.location.hostname}:3000/api`;
  }

  get userIsAuthenticated() {
    return this._user.asObservable()
      .pipe(map(user => {
        if (user) {
          return !!user.token
        } else {
          return false;
        }
      }
      ));
  }

  get userId() {
    return this._user.asObservable().pipe(map(user => {
      if (user) {
        return user.email;
      } else {
        return null;
      }
    }));
  }

  get token() {
    return this._user.asObservable().pipe(map(user => {
      if (user) {
        
        return user.token;
      } else {
        return null;
      }
      
    }))

    
  }

  autoLogin() {
    return from (Preferences.get({key: 'authData'}))
      .pipe(map(storedData => {
        if (!storedData || !storedData.value) {
          return null;
        }
        const parsedData = JSON.parse(storedData.value) as {
          email:string; 
          token: string; 
          tokenExpirationDate: string
        };
        const expiresAt = new Date(parsedData.tokenExpirationDate);

        if (expiresAt < new Date()) {
          return null;
        }
        const user = new User(
          parsedData.email,
          parsedData.token,
          expiresAt
        )
        return user;
      }),
      tap(user => {
        if (user) {
          this._user.next(user);
        }
      }),
      map(user => {
        return !!user;
      })
      
      );

  }

  createUser(
    //username:string, 
    email: string, password:string) {
    const authData = {
      //username: username, 
      email: email, 
      password: password
    }

    return this.http.post<AuthResponseData>(`${this.apiUrl}/user/signup`, authData)
      .pipe(tap(this.setUserData.bind(this)))
    
      /*
    .pipe(tap(userData => {
        this._user.next(new User(userData.email, userData.token, new Date(userData.expiresAt) ))
        console.log(this._user)
      }))
      */
    //  .subscribe(response => {
    //    console.log(response);
    //  });
  }

  login(email:string, password:string) {
    const authData = {
      email: email, 
      password: password
    }
    return this.http.post<AuthResponseData>(`${this.apiUrl}/user/login`, authData)
    .pipe(tap(this.setUserData.bind(this)))
  }

  logout () {
    this._user.next(null);
    Preferences.remove({key:'authData'});
  }

  private setUserData(userData: AuthResponseData) {
    this._user.next(
      new User(
        userData.email,
        userData.token,
        new Date(userData.expiresAt)
      )
    )
    console.log(this._user)
    this.storeAuthData(
      userData.email, 
      userData.token, 
      userData.expiresAt
    );
  }

  private storeAuthData(email: string, token: string, tokenExpirationDate: string) {
    const data = {email: email, token: token, tokenExpirationDate: tokenExpirationDate};
    console.log(data)
    console.log(JSON.stringify(data))


    //Storage.set({
    Preferences.set({
      key: 'authData',
      value: JSON.stringify(data)
    })

  }



}
