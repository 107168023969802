import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProductsService } from 'src/app/product/products.service';
import { AddProductToProjectComponent } from '../add-product-to-project/add-product-to-project.component';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit {
  //@Input() item;
  @Input() source;
  @Input() sku;
  @Input() matchCount;
  isLoading = false;
  isMatchLoading: boolean;
  //isMatchComponentLoading = true;
  item = [];
  productData;
  prodData = [];

  //prods = []; 
  matched: any = [];
  descriptionPoints =[];

  constructor(
    private modalCtrl: ModalController,
    private productService: ProductsService
    ) { }

  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }

  ngOnInit() {
    this.isLoading = true;
    this.productService.getItem(this.source, this.sku)
    .subscribe(data => {
      console.log(data);
      this.item = data[0];
    });
    console.log('mathcCount:'+ this.matchCount)

    this.productService.getProductDetail(this.source, this.sku)
      .subscribe(productData => {
        console.log(productData)
        this.productData = productData;
        //this.prodData = Object.values(productData);
        
        for (const key in productData) {
          //if (productData[key] =='attributename'){
            //const value = productData[key];
            //Object.assign(this.prodData, {[key]: value});
            for (const k in productData[key]) {
              if (k == 'attributename'){
                //console.log(k)
                //console.log(productData[key][k])
                //console.log(productData[key]['attributevalue'])
                const attr = productData[key][k]
                const value = productData[key]['attributevalue']
                //var obj = {[attr]: value}
                //var obj = jsonObj[attr = value]
                //console.log(obj)
                //this.prodData.push(obj)

                Object.assign(this.prodData, { [attr]: value })

                //this.prodData.push({[attr]: value})
                

              }
            }

          //}
        }
        console.log(this.prodData);
        let j = 0
        for (const i in this.prodData){
          //console.log(i)
          if (i.includes('DescriptionPoint')) {
            console.log(this.prodData[i])
            const value = this.prodData[i]
            console.log(j)
            Object.assign(this.descriptionPoints, {[j]:value} )
            j += 1
            console.log(j)

          }
        }
        console.log(this.descriptionPoints);
        /*for (const data of this.prodData) {
          console.log(data)
          console.log(data.)
          this.prods[data] = data.value

          
        }*/
        /*
        const keys = Object.keys(this.prodData);
        const values = Object.values(this.prodData);

        console.log(keys);
        console.log(values);
        for (let i = 0; i < keys.length; i++) {
          //console.log(this.prodData[i])
          const keys2 = Object.keys(this.prodData[i]);
          const values2 = Object.values(this.prodData[i]);
          //console.log(keys2);
          //console.log(values2);
          for (const j in keys2){

            this.prods[keys2[j]]= values2[j]
          }
          

        }

        console.log(this.prods)
        */
       //this.prodData = {...productData}
      
      /* if (this.matchCount > 0) {
        this.productService.getItemMatch(this.source, this.sku)
        .subscribe(data => {
          console.log(data);
          this.matched = data;
          //this.isMatchLoading = true;
        });
      } else {
        //this.isMatchLoading = true;
      }
      */
        this.isLoading=false;
      })
      //.subscribe(result => {
      //  console.log(result)
      //})
    //this.isLoading=false;
  }

  openNewWindow(url) {
    window.open(url,'_blank')
  }

  getLogo(source: string): string {
    const logoUrl = this.productService.getLogoUrlBySourceSystem(source);
    return logoUrl || source;
  }

  async addToProjectModal(source, sku, id, title){
    const modal = await this.modalCtrl.create({
      component: AddProductToProjectComponent,
      componentProps: {
        'source': source,
        'sku': sku,
        'id': id,
        'title': title,
        modalController: this.modalCtrl
      }
    });
    modal.present();
  }

}
