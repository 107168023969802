import { Component } from '@angular/core';
import { ProductsService } from './product/products.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private productService: ProductsService
  ) {
    this.productService.fetchLogos()
  }
}
