import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface Post {
  id: string;
  title: string;
  content: string;
}



@Injectable({
  providedIn: 'root'
})

export class ProductsService {
  private posts: Post[] = [];
  private postsob = new Subject<Post[]>();

  private saleItems: any = [];
  private saleItemsOb = new Subject();
  private saleItemsByAmountOb= new Subject();

  private saleItemsMore: any = [];
  private saleItemsMoreOb = new Subject();

  private logos: any = [];

  orderby:string;
  offset;
  limit;

  // Define the API base URL as a property
  private apiUrl: string;


  constructor(private http: HttpClient) {
    this.apiUrl = `${window.location.protocol}//${window.location.hostname}:3000/api`;
   }
  /*
  fetchLogos(): Observable<any> {
    return this.http.get('http://localhost:3000/api/logo').pipe((data) => {
      this.logos = data;
      return data;
    });
  }
*/
  fetchLogos() {
    this.http.get<{rows: []}>(`${this.apiUrl}/logo`).subscribe(data => {
      this.logos = data.rows;
      console.log(this.apiUrl)
    });
  }  

  getLogoUrlBySourceSystem(sourceSystem: string): string | null {
    const logo = this.logos.find(logoData => logoData.sourcesystem === sourceSystem);
    return logo ? logo.logo_url : null;
  }

  getPosts() {
    this.http.get<{message: string, posts: Post[]}>(`${this.apiUrl}/posts`)
      .subscribe((postData) => {
        this.posts = postData.posts;
        this.postsob.next([...this.posts]);


      });
  }
  getPostUpdateListener() {
    return this.postsob.asObservable();
  }

  getSaleItems(orderby,offset,limit) {
    this.offset=offset.toString();
    this.limit=limit.toString();
    return this.http.get(`${this.apiUrl}/sale?orderby=${orderby}&offset=${this.offset}&limit=${this.limit}`)
  }

  getProductDetail(source,sku) {
    console.log(source);
    console.log(sku);
    return this.http.get(`${this.apiUrl}/productdetail/?source=${source}&sku=${sku}`)
  }

  getItem(source,sku) {
    return this.http.get(`${this.apiUrl}/product/?source=${source}&sku=${sku}`)
  }

  getItemMatch(source,sku) {
    return this.http.get(`${this.apiUrl}/productmatch/?source=${source}&sku=${sku}`)
  }

  getProductSearch(searchTerm, offset,limit) {
    this.offset=offset.toString();
    this.limit=limit.toString();
    let searchTermStrip = searchTerm.replace(/\s+/g, ' ').trim();
    return this.http.get(`${this.apiUrl}/product/search/?text=${searchTermStrip}&offset=${this.offset}&limit=${this.limit}`)
  }

  getFilterCriteria(){
    return this.http.get(`${this.apiUrl}/product/filtercriteria`)
  }

  
 

}
