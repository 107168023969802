import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactUsService } from './contact-us.service';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router'; // Import Router

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private contactService: ContactUsService,
    private alertController: AlertController, // Inject AlertController
    private router: Router // Inject Router
  ) {}

  ngOnInit() {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });
  }

  async presentAlert(header: string, message: string, callback?: () => void) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: [{
        text: 'OK',
        handler: () => {
          if (callback) {
            callback();
          }
        }
      }]
    });

    await alert.present();
  }

  onSubmit() {
    if (this.contactForm.valid) {
      console.log(this.contactForm);
      this.contactService.sendMessage(this.contactForm.value).subscribe(
        response => {
          console.log('Message sent successfully', response);
          this.presentAlert('Success', 'Message sent successfully', () => {
            this.contactForm.reset();
            this.router.navigate(['/']);
          });
        },
        error => {
          console.error('Error sending message', error);
          this.presentAlert('Error', 'Failed to send message. Please try again later.');
        }
      );
    }
  }
}
