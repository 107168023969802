import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';



export interface ProjectResponseData {
  message: string;
  count: number;
  data: [];
}

export interface CreateProjectResponseData {
  message: string;
  records_added: number;
  data: [];
}

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  private apiUrl: string;
  private projectDataSubject = new BehaviorSubject<any>(null);
  projectData$ = this.projectDataSubject.asObservable();

  private itemsUpdated = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient    
  ) { 
    this.apiUrl = `${window.location.protocol}//${window.location.hostname}:3000/api`;
  }


  //handle project items being removed
  
  getModalUpdated() {
    return this.itemsUpdated.asObservable();
  }
  updateModal(value: boolean) {
    this.itemsUpdated.next(value);
  }
  

  getProjects(email:string) {
    const params = {
      email: email
    }
    return this.http.get<ProjectResponseData>(`${this.apiUrl}/project/projects`, {params})
  };

  deleteProject(id:number, email:string) {
    const body = {
      email: email
    };
    return this.http.delete(`${this.apiUrl}/project/projects?id=${id}`,{body})
  };

  updateProject(id:number, email:string, name: string, description: string) {
    const body = {
      email: email,
      name : name,
      description: description
    }
    return this.http.patch(`${this.apiUrl}/project/projects?id=${id}`,body)
  };



  getProjectItems(id:number, email:string) {
    const params = {
      email: email
    };
    return this.http.get(`${this.apiUrl}/project/products?id=${id}`, {params})
  }
  

  createProject<CreateProjectResponseData>(email:string, name:string, description:string) {
    const body = {
      email: email,
      name: name,
      description: description
    };
    return this.http.post(`${this.apiUrl}/project/projects`, body)
  }

  setProjectData(data: any){
    this.projectDataSubject.next(data);
  }

  addProductToProject(id: number, projectIds: number[], email:string) {
    const body = {
      email: email
    };
    projectIds.forEach(projectId => {
      // Construct the URL with projectId and id
      const url = `${this.apiUrl}/project/products?project_id=${projectId}&product_id=${id}`;
  
      // Make the HTTP request
      this.http.post(url,body).subscribe(response => {
        // Handle the response here if needed
        console.log(`Added product ${id} to project ${projectId}`);
      });
    });
  }

  removeProductFromProject(id: number, projectId: number, email: string) {
    const url = `${this.apiUrl}/project/products?project_id=${projectId}&product_id=${id}&email=${email}`;
    return this.http.delete(url);
  }

}
