import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('header', { read: ElementRef }) headerElement: ElementRef;
  private lastVisitedUrl: string = '/';
  private isAuthenticated: boolean = false;
  logoUrl: string = 'assets/logo.png';

  isMenuOpen: boolean = false;
  
  constructor(
    private authService: AuthService,
    private router: Router,
    private menuController: MenuController
    ) { 
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.lastVisitedUrl = event.url;
          console.log(event)
          console.log(this.lastVisitedUrl)

        }
      });

      this.authService.userIsAuthenticated.subscribe(
        authenticated => {
          this.isAuthenticated=authenticated
        }
      )

    }
    ionViewWillEnter() {
      // Check the menu state and update 'isMenuOpen' accordingly
      const menu = this.menuController.get('main-content');
  
    }

  ngOnInit() {}

  onLogout() {
    this.authService.logout();

    this.router.navigateByUrl('/tabs/offers')
    //this.router.navigateByUrl(`/auth?redirect=${this.lastVisitedUrl}`)
  }

  onLogin(){
    this.router.navigateByUrl(`/auth?redirect=${this.lastVisitedUrl}`)
  };

  async onOpenMenu() {
    const menu = this.menuController.get('main-content'); // Get a reference to the menu with contentId "main-content"
    console.log(menu)
    if (menu) {
      await this.menuController.open('main-content'); // Open the menu
    }
    
  }
  
  async closeMenu() {
    await this.menuController.close();
  }
  
  /*
  async onOpenMenu() {
    this.menuController.open('menu')
    const menu = this.menuController.get('main-content');
    console.log(menu)
  }
  */



}
