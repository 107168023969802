import { Component, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProductsService } from 'src/app/product/products.service';

import { ProductDetailComponent } from '../product-detail/product-detail.component';

@Component({
  selector: 'app-compare-items',
  templateUrl: './compare-items.component.html',
  styleUrls: ['./compare-items.component.scss'],
})
export class CompareItemsComponent implements OnInit {
  @Input() source;
  @Input() sku;
  @Input() matchCount;
  items:any = [];
  isMatchComponentLoading = true;

  constructor(
    public productService: ProductsService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    console.log(this.source);
    console.log(this.sku);
    console.log(this.matchCount);
    if (this.matchCount == 0) {
      this.isMatchComponentLoading = false;
    } else {

      this.productService.getItemMatch(this.source,this.sku)
      .subscribe(matchedData => {
        this.items = matchedData;
        console.log(this.items)
        this.isMatchComponentLoading = false;
        
      })
    }
  }

  openNewWindow(url) {
    window.open(url,'_blank')
  }

  getLogo(source: string): string {
    const logoUrl = this.productService.getLogoUrlBySourceSystem(source);
    return logoUrl || source;
  }

  async openModal(source, sku, matchCount) {
    const modal = await this.modalCtrl.create({
      component: ProductDetailComponent,
      componentProps: {
        'source': source,
        'sku': sku,
        'matchCount': matchCount
      }
    });
    modal.present();
  }

}
