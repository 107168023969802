import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ProductDetailComponent } from '../product-detail/product-detail.component';
import { AddProductToProjectComponent } from '../add-product-to-project/add-product-to-project.component';
import { ProjectsService } from '../../projects/projects.service';
import { ProductsService } from 'src/app/product/products.service';


@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() productItems;
  @Input() inProject: boolean = false;
  @Input() projectId: number = null;
  @Input() email: string = '';

  @Output() itemRemoved: EventEmitter<number> = new EventEmitter<number>();

  
  constructor(
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private projectService: ProjectsService,
    private productService: ProductsService
  ) {
  }

  ngOnInit() {}

  getLogo(source: string): string {
    const logoUrl = this.productService.getLogoUrlBySourceSystem(source);
    return logoUrl || source;
  }
/*
  getLogo(source) {
    if (source =='Screwfix') {
      return 'https://cdn-fsly.yottaa.net/581cc04c2bb0ac43a7000001/www.screwfix.com/v~4b.49f/assets/gfx/branding/screwfix-logo.svg'
    } else if (source == 'Toolstation') {
      return 'https://www.toolstation.com/img/toolstation-halo.svg';
    } else if (source == 'Wickes') {
      return 'https://static-content.wickes.co.uk/wickes-css@2.64.0/build/img/wickes-logo.svg';
    } else {
      return source
    }
  }
  */

  openNewWindow(url) {
    window.open(url,'_blank')
  }

  async openModal(source, sku, matchCount) {
    const modal = await this.modalCtrl.create({
      component: ProductDetailComponent,
      componentProps: {
        'source': source,
        'sku': sku,
        'matchCount': matchCount
      }
    });
    modal.present();
  }

  async addToProjectModal(source, sku, id, title){
    const modal = await this.modalCtrl.create({
      component: AddProductToProjectComponent,
      componentProps: {
        'source': source,
        'sku': sku,
        'id': id,
        'title': title,
        modalController: this.modalCtrl
      }
    });
    modal.present();
  }

  async removeFromProjectAlert(id){
    const alert = await this.alertController.create({
      header: 'Remove Item',
      message: 'Remove item from this project?',
      buttons: [
        {
          text: 'No',
          role: 'cancel', // This role represents a cancel action
          handler: () => {
            // User clicked "No," do nothing
            console.log('Removal canceled');
          },
        },
        {
          text: 'Yes',
          handler: () => {
            // User clicked "Yes," call the removal function
            this.projectService.removeProductFromProject(id, this.projectId,this.email).subscribe(
              (response) => {
                // Handle the removal success
                console.log('Item removed successfully');
                this.itemRemoved.emit(id);
              },
              (error) => {
                // Handle the removal error
                console.error('Error removing item:', error);
              }
            );
          },
        },
      ],
    });
  
    await alert.present();
  }


  


}
